import { Flex } from '@iheartradio/web.accomplice/flex';
import type { CarouselProps } from '@iheartradio/web.companion';
import { Box, Carousel, Text } from '@iheartradio/web.companion';
import { type ReactNode, memo, useMemo } from 'react';

export type CardCarouselKind = 'content' | 'featured' | 'news' | 'row';

export type CardCarouselProps = CarouselProps & {
  color?: string;
  description?: ReactNode;
  kind: CardCarouselKind;
  hasAds?: boolean;
};

const getSlidesToShowMap = ({
  description,
}: {
  description?: ReactNode;
}): Record<CardCarouselKind, CarouselProps['slidesToShow']> => {
  return {
    content: {
      '@initial': 2,
      '@small': 3,
      '@medium': description ? 3 : 4,
      '@large': description ? 3 : 5,
      '@xlarge': description ? 5 : 7,
    },
    featured: {
      '@initial': 1,
      '@small': 1,
      '@shmedium': 2,
      '@medium': 2,
      '@large': 2,
      '@xlarge': 3,
    },
    news: {
      '@initial': 1,
      '@small': 2,
      '@medium': 3,
      '@large': 3,
      '@xlarge': 5,
    },
    row: {
      '@initial': 1,
      '@small': 1,
      '@medium': 2,
      '@large': 1,
      '@xlarge': 2,
    },
  };
};

const GradientBackground = memo(function GradientBackground({
  color,
}: {
  color: string;
}) {
  const isTransparent = useMemo(() => color === 'transparent', [color]);
  return (
    <Box
      background={`linear-gradient(0.25turn, transparent, ${color} 50%, transparent)`}
      bottom="$0"
      opacity={{
        light: isTransparent ? '0' : '0.25',
        dark: isTransparent ? '0' : '0.35',
      }}
      position="absolute"
      top="$0"
      transition="opacity .5s linear"
      width="100%"
      zIndex="-1"
    />
  );
});

export const CardCarousel = ({
  children,
  color = 'transparent', // defaulting to 'transparent' helps avoid some significant layout shift
  description,
  kind,
  title,
  ...props
}: CardCarouselProps) => {
  const slidesToShowMap = useMemo(
    () => getSlidesToShowMap({ description }),
    [description],
  );

  const slidesToShow = slidesToShowMap[kind];

  return (
    <Box
      data-test="card-carousel"
      padding="$16 0"
      position="relative"
      width="100%"
      zIndex="$0"
    >
      <GradientBackground color={color} />

      {description ?
        <Flex
          alignItems={{ mobile: 'left', medium: 'center' }}
          data-test="featured-layout"
          direction={{ mobile: 'column', medium: 'row' }}
        >
          <Flex
            alignItems="left"
            direction="column"
            gap="$8"
            minWidth={{
              medium: '$5',
              large: '$3',
              xlarge: '$3',
            }}
            padding={{ mobile: '$0 $16', large: '$0 $0 $0 $32' }}
          >
            <Text
              as="h3"
              kind={{ '@initial': 'h4', '@large': 'h3' }}
              width="100%"
            >
              {title}
            </Text>
            <Text as="p" kind={{ '@initial': 'body-4', '@large': 'body-3' }}>
              {description}
            </Text>
          </Flex>
          <Box overflow="hidden">
            <Carousel slidesToShow={slidesToShow} {...props}>
              {children}
            </Carousel>
          </Box>
        </Flex>
      : <Carousel slidesToShow={slidesToShow} title={title} {...props}>
          {children}
        </Carousel>
      }
    </Box>
  );
};

CardCarousel.displayName = 'CardCarousel';

export const Slide = Carousel.Slide;
